import './chordContainer.css'
import { DndSample } from './draggableChords.tsx'


const chordContainerStyle = {
    display: 'flex',
    minHeight: '60vh',
    justifyContent: 'space-evenly'
  }

function ChordContainer(props: any) {
    const { state, setState } = props

    return (
        <div style={ chordContainerStyle } className="chord-container">
            <DndSample state={ state } setState={ setState } />
        </div>
    ) 
}

export default ChordContainer
