import './App.css';
import * as Tone from 'tone';
import {Box, Button, CircularProgress, Drawer, Grid, Modal, Slider, Typography } from '@mui/material';
import { useState } from 'react';
import ChordContainer from './components/chordContainer';
import { generateRandomChord } from './components/chord';
import { v4 as uuidv4 } from 'uuid';


// ?Tone.context.resume()

let moreRandomness = false;
const initialTempo = 120;
Tone.Transport.bpm.value = initialTempo;
let metronomeSampler = new Tone.Sampler().toDestination();
let sampler = new Tone.Sampler().toDestination();


const noteArray = ['C', 'C#', 'D', 'D#', 'E', 'F', 'F#', 'G', 'G#', 'A', 'A#', 'B', 'C', 'C#', 'D', 'D#', 'E', 'F', 'F#', 'G', 'G#', 'A', 'A#', 'B', 'C', 'C#']

const chordDict: Record<string, number[]> = {
  'maj': [4, 7],
  'min': [3, 7],
  'dim': [3, 6],
  'aug': [4, 8],
  'sus4': [5, 7],
  'sus2': [2, 7]
}

const extensionDict: Record<string, number> = {
  '7': 10,
  '7+': 11,
  '9': 14
}

function choice(array: any) {
  return array[Math.floor(Math.random() * array.length)];
};

function nfi(note: string, interval: number) {
  return noteArray[noteArray.indexOf(note) + interval]
}

const getChordNotesFromChord = (text: string): string[] => {
  let [chord_note, adjective, extension] = text.split(' ')
  let bass_note = chord_note + choice(['1', '2'])
  let intervals = chordDict[adjective].map(interval => nfi(chord_note, interval) + choice(['3','4','5']))
  let extensionNotes = []
  if (extension) {
    extensionNotes.push(nfi(chord_note, extensionDict[extension]) + choice(['3','4','5']))
  }
  return [bass_note, ...intervals, ...extensionNotes]
}

const playNextChord = (time: number) => {
  let selectedChord = document.querySelector('.chord.selected')
  let playNextChord = document.querySelector('.chord.play-next')
  let chord = null
  let chordNotes: string[] = []
  if (playNextChord !== null) {
    chord = playNextChord
    chord.classList.remove('play-next')
  } else if (selectedChord) {
    selectedChord.classList.remove('selected')
    if (selectedChord.parentElement !== null && selectedChord.parentElement.parentElement !== null) {
      chord = (selectedChord.parentElement.parentElement.nextSibling as HTMLElement)?.querySelector('.chord')
      let selectedChordName = selectedChord.querySelector('h2.chord-name')
      if ( moreRandomness && selectedChordName !== null ) {
        selectedChordName.innerHTML = generateRandomChord().displayName
      }
    }
    if (chord === null || chord === undefined) {
      chord = document.querySelector('.chord')
    }
  } else {
    chord = document.querySelector('.chord')
  }

  if (chord !== null) {
    chord.classList.add('selected')
    let chordNotesNameElement = chord.querySelector('.chord-name')
    if (chordNotesNameElement !== null) {
      chordNotes = getChordNotesFromChord((chordNotesNameElement as HTMLElement).innerText.trim())
    }
  }

  sampler.triggerAttackRelease(chordNotes, '1m', time)
}

const createNewLoop = () => {
  let newLoop = new Tone.Loop((time) => {
    try {
      playNextChord(time)
    }
    catch (error) {
      console.log('Could not play the next chord...')
    }
  }, '1m')
  return newLoop
}

const createMetronomeLoop = () => {
  let newLoop = new Tone.Loop((time) => {
    metronomeSampler.triggerAttackRelease(['C3'], '4n', time)
  }, '4n')
  return newLoop
}

const loop = createNewLoop()
const metronomeLoop = createMetronomeLoop()


const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const initialState = Array(8).fill(0).map(x => uuidv4())

function App() {

  // STATES
  const [initialModalOpen, setInitialModalOpen] = useState(true)
  const [useMetronome, setUseMetronome] = useState(true)
  const [tempo, setTempo] = useState(initialTempo)
  const [moreRandomnessState, setMoreRandomnessState] = useState(false)
  const [metronomeVolume, setMetronomeVolume] = useState(-10)
  const [pianoVolume, setPianoVolume] = useState(-10)
  const [isLoading, setIsLoading] = useState(false)
  const [state, setState] = useState(initialState)
  const [drawerOpen, setDrawerOpen] = useState(false)


  const handleCloseInitialModal = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, reason: string) => {
    if (reason !== 'backdropClick') {
      setInitialModalOpen(false)
      await Tone.start()
      console.log('Audio is ready!')
    }
    metronomeSampler = new Tone.Sampler({
      urls: {
        C3: "./woodblock.mp3"
      },
      release: 1,
      onload: () => {}
    }).toDestination();
    sampler = new Tone.Sampler({
      urls: {
        A0: "A0.mp3",
        C1: "C1.mp3",
        "D#1": "Ds1.mp3",
        "F#1": "Fs1.mp3",
        A1: "A1.mp3",
        C2: "C2.mp3",
        "D#2": "Ds2.mp3",
        "F#2": "Fs2.mp3",
        A2: "A2.mp3",
        C3: "C3.mp3",
        "D#3": "Ds3.mp3",
        "F#3": "Fs3.mp3",
        A3: "A3.mp3",
        C4: "C4.mp3",
        "D#4": "Ds4.mp3",
        "F#4": "Fs4.mp3",
        A4: "A4.mp3",
        C5: "C5.mp3",
        "D#5": "Ds5.mp3",
        "F#5": "Fs5.mp3",
        A5: "A5.mp3",
        C6: "C6.mp3",
        "D#6": "Ds6.mp3",
        "F#6": "Fs6.mp3",
        A6: "A6.mp3",
        C7: "C7.mp3",
        "D#7": "Ds7.mp3",
        "F#7": "Fs7.mp3",
        A7: "A7.mp3",
        C8: "C8.mp3"
      },
      release: 1,
      baseUrl: "./piano/", //source: https://archive.org/details/SalamanderGrandPianoV3
      onload: () => {}
    }).toDestination();
  }

  function startPlayback() {
    metronomeLoop.start()
    loop.start()
    Tone.Transport.start()
  }

  function stopPlayback() {
    let selectedChord = document.querySelector('.chord.selected')
    if (Tone.Transport.state === 'stopped' && selectedChord) {
      selectedChord.classList.remove('selected')
    }

    Tone.Transport.stop();
    if (metronomeLoop) {
      metronomeLoop.dispose()
    }
    if (loop) {
      loop.dispose()
    }
  }

  const toggleMetronome = () => {
    let newMetronomeState = !useMetronome
    setUseMetronome(newMetronomeState)
    if (newMetronomeState) {
      setMetronomeVolume(0)
      metronomeSampler.volume.value = 0
    } else {
      setMetronomeVolume(-50)
      metronomeSampler.volume.value = -50
    }
  }
  
  const removeBar = () => {
    if (state.length - 1 > 0) {
      let newState = [...state].slice(0, state.length - 1)
      setState(newState)
    }
  }

  const addBar = () => {
    if (state.length + 1 <= 16) {
      let newState = [...state]
      newState.push(uuidv4())
      setState(newState)
    }
  }

  const generateNewProgression = () => {
    setIsLoading(true)
    setTimeout(
      () => {
        setIsLoading(false)
      },
      1000
    )
  }

  const handleTempoChange = (event: any, newValue: any) => {
    Tone.Transport.bpm.value = newValue
    setTempo(newValue)
  }

  const handleMetronomeVolumeChange = (event: any, newValue: any) => {
    metronomeSampler.volume.value = newValue
    setMetronomeVolume(newValue)
  }

  const handlePianoVolumeChange = (event: any, newValue: any) => {
    sampler.volume.value = newValue
    setPianoVolume(newValue)
  }

  const toggleInfiniteRandomness = () => {
    setMoreRandomnessState(!moreRandomnessState)
    moreRandomness = !moreRandomnessState
  }

  return (
    <div className="App">
      {
        isLoading
          ? <div className="circular-progress-wrapper">
              <CircularProgress className="circular-progress" />
            </div>
          : <ChordContainer state={ state } setState={ setState }/> 
      }
      <div>
        <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 12, sm: 12, md: 12 }} style={{ marginBottom: '2rem' }}>
          <Grid  xs={6} sm={3} item>
            <Button onClick={ startPlayback }>START</Button>
          </Grid>
          <Grid  xs={6} sm={3} item>
            <Button onClick={ stopPlayback }>STOP</Button>
          </Grid>
          <Grid  xs={6} sm={3} item>
            <Button onClick={ toggleMetronome }>METRONOME: { useMetronome ? 'ON' : 'OFF' }</Button>
          </Grid>
          <Grid  xs={6} sm={3} item>
            <Button onClick={ toggleInfiniteRandomness }>RANDOMIZE: { moreRandomness ? 'ON' : 'OFF' }</Button>
          </Grid>
          <Grid  xs={6} sm={3} item>
            <Button onClick={ removeBar }>REMOVE BAR</Button>
          </Grid>
          <Grid  xs={6} sm={3} item>
            <Button onClick={ addBar }>ADD BAR</Button>
          </Grid>
          <Grid  xs={6} sm={3} item>
            <Button onClick={ generateNewProgression }>NEW PROGRESSION</Button>
          </Grid>
        </Grid>
        <Button onClick={ () => { setDrawerOpen(true) } }>OPEN SETTINGS</Button>
        <Drawer
          anchor={'bottom'}
          open={ drawerOpen }
          onClose={() => { setDrawerOpen(false) }}
        >
          <div style={{ textAlign: 'center', margin: '2rem' }}>
            <Typography gutterBottom>Tempo: <strong>{ tempo }</strong> bpm</Typography>
            <Slider
              aria-label="Tempo slider"
              className="main-slider"
              min={20}
              max={220}
              step={4}
              value={tempo}
              onChange={handleTempoChange}
            />
            <Typography gutterBottom>Metronome volume: <strong>{ (metronomeVolume + 50) * 2 }</strong></Typography>
            <Slider
              aria-label="Metronome volume slider"
              className="main-slider"
              min={-50}
              max={0}
              step={2}
              value={metronomeVolume}
              onChange={handleMetronomeVolumeChange}
            />
            <Typography gutterBottom>Piano volume: <strong>{ (pianoVolume + 50) * 2 }</strong></Typography>
            <Slider
              aria-label="Piano volume slider"
              className="main-slider"
              min={-50}
              max={0}
              step={2}
              value={pianoVolume}
              onChange={handlePianoVolumeChange}
            />
          </div>
        </Drawer>
      </div>
      <Modal
        open={initialModalOpen}
        onClose={handleCloseInitialModal}
        aria-labelledby="initial-modal-title"
        aria-describedby="initial-modal-description"
      >
        <Box className='acknowledgement-modal' sx={ modalStyle }>
          <Typography id="initial-modal-title" variant="h6" component="h2">
            Acknowledgement
          </Typography>
          <Typography id="initial-modal-description" sx={{ mt: 2 }}>
            This app uses audio, but this requires a "user action" to get it started.
            It's to prevent stuff like ads playing automatically etc.
            So if you're ready to hear audio from this browser tab, then click the button below.
          </Typography>
          <Button onClick={ event => handleCloseInitialModal(event, '') }>
            OK, I kinda expected that.
          </Button>
        </Box>
      </Modal>
    </div>
  )
}

export { App, choice, modalStyle }
