import React, { ReactElement, useCallback } from "react";

import {
  DndContext,
  KeyboardSensor,
  MouseSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import { arrayMove, SortableContext, useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { Chord } from './chord'
import MenuIcon from '@mui/icons-material/Menu';


interface SortableItemProps {
  id: string;
  children: ReactElement;
}

function SortableItem({ id, children }: SortableItemProps) {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id });
  const style = {
    transform: CSS.Transform.toString(transform),
    transition
  };
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <div ref={setNodeRef} style={style} className='draggable-chord'>
      <button className="drag-handle" style={{ cursor: 'move', userSelect: 'none', height: '30%', width: '100%' }}  {...attributes} {...listeners}>
        <MenuIcon />
      </button>
      <div>
        {children}
      </div>
    </div>
  );
}

function DndSample(props: any): JSX.Element {
  const { state, setState } = props
  // const [state, setState] = useState<{ id: string; content: string }[]>(contents);
  const handleDragEnd = useCallback(
    (event: any) => {
      const { active, over } = event;
      if (over === null) {
        return;
      }
      if (active.id !== over.id) {
        const oldIndex = state
          .map((item: any) => {
            return item;
          })
          .indexOf(active.id);
        const newIndex = state
          .map((item: any) => {
            return item;
          })
          .indexOf(over.id);
        const newState = arrayMove(state, oldIndex, newIndex);
        setState(newState);
      }
    },
    [state, setState]
  );

  const mouseSensor = useSensor(MouseSensor, {
    activationConstraint: {
      distance: 15,
    },
  });
  const keyboardSensor = useSensor(KeyboardSensor);
  const sensors = useSensors(mouseSensor, keyboardSensor);

  return (
    <DndContext onDragEnd={handleDragEnd} sensors={sensors}>
      <SortableContext items={state}>
        <div style={{ display: "flex", flexWrap: "wrap", justifyContent: 'space-evenly', alignItems: 'center', alignContent: 'center' }}>
          {state.map((item: any) => (
            <SortableItem key={item} id={item}>
              <Chord chordId={item} state={state} setState={setState} />
            </SortableItem>
          ))}
        </div>
      </SortableContext>
    </DndContext>
  );
}

export { DndSample }