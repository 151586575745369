import './chord.css'
import {Box, Button, FormControl, InputLabel, MenuItem, Modal, Select, Typography } from '@mui/material';
import React, { useState } from 'react'
import { choice, modalStyle } from '../App'


const keys = ['A', 'A#', 'B', 'C', 'C#', 'D', 'D#', 'E', 'F', 'F#', 'G', 'G#']
const extensions = ['7', '7+', '9']
const specials = ['dim', 'aug', 'sus2', 'sus4']

function generateRandomChord() {
    let key = choice(keys);
    let third = Math.random() < 0.5 ? "min" : "maj";
    let extension = Math.random() < 0.3 ? choice(extensions) : "";
    let special = Math.random() < 0.1 ? choice(specials) : "";
    let displayName = special ? `${key} ${special}` : `${key} ${third} ${extension}`;
    
    let data = {
        rootNote: key,
        minMaj: third,
        extension: extension,
        special: special,
        displayName: displayName.trim()
    }
    
    return data;
};

const parseDisplayName = (displayName: string) => {
    let [rootNote, adjective, extension] = displayName.split(' ')
    let minMaj = ''
    let special = ''
    if (specials.filter(x => adjective === x).length >= 1) {
        special = adjective
    } else {
        minMaj = adjective
    }
    if (!extension) {
        extension = ''
    }
    return [ rootNote, minMaj, extension, special ]
}

const initialNote = {
    rootNote: '',
    minMaj: '',
    extension: '',
    special: '',
    displayName: ''
}

function Chord(props: any) {
    const { chordId, state, setState } = props

    const [ rootNote, setRootNote ] = useState('')
    const [ minMaj, setMinMaj ] = useState('')
    const [ extension, setExtension ] = useState('')
    const [ special, setSpecial ] = useState('')
    const [ modalOpen, setModalOpen ] = useState(false)
    const [ beforeChanges, setBeforeChanges ] = useState(initialNote)
    const [ chordData, setChordData ] = useState(generateRandomChord())

    const controlStyle = {
        margin: '1rem 0'
    }

    const handleApplyChanges = () => {
        setChordData({
            rootNote: rootNote,
            minMaj: minMaj,
            extension: extension,
            special: special,
            displayName: special ? `${rootNote} ${special}` : `${rootNote} ${minMaj} ${extension}`.trim()
        })
        setModalOpen(false)
    }
    
    function handleOpenChangeChordModal(event: React.MouseEvent<Element, MouseEvent>) {
        if (event.target instanceof Element && event.target.parentElement !== null && event.target.parentElement.innerText !== null) {
            let currentDisplayName = (event.target.parentElement.querySelector('.chord-name') as HTMLElement).innerText.trim()
            let [ currentRootNote, currentMinMaj, currentExtension, currentSpecial ] = parseDisplayName(currentDisplayName)
            setBeforeChanges({
                rootNote: currentRootNote,
                minMaj: currentMinMaj,
                extension: currentExtension,
                special: currentSpecial,
                displayName: currentDisplayName
            })
            setRootNote(currentRootNote)
            setSpecial(currentSpecial)
            if (currentSpecial === '') {
                setMinMaj(currentMinMaj)
                setExtension(currentExtension)
            }
            setModalOpen(true)
        }

    }

    const handleCancelChanges = () => {
        setRootNote(beforeChanges.rootNote)
        setMinMaj(beforeChanges.minMaj)
        setExtension(beforeChanges.extension)
        setSpecial(beforeChanges.extension)
        setChordData({
            rootNote: beforeChanges.rootNote,
            minMaj: beforeChanges.minMaj,
            extension: beforeChanges.extension,
            special: beforeChanges.special,
            displayName: beforeChanges.displayName
        })
        setModalOpen(false)
    }
    
    const handleChangeRootNote = (target: any, choice: any) => {
        setRootNote(choice.props.value)
    }

    const handleChangeMinMaj = (target: any, choice: any) => {
        setMinMaj(choice.props.value)
    }

    const handleChangeExtension = (target: any, choice: any) => {
        setExtension(choice.props.value)
    }

    const handleChangeSpecial = (target: any, choice: any) => {
        setSpecial(choice.props.value)
    }

    const handlePlayNext = () => {
        let selectedChord = document.querySelector('.selected')
        if (selectedChord !== null) {
            selectedChord.classList.remove('selected')
        }
        let chord = document.getElementById(chordId)
        if (chord !== null && chord.parentElement !== null) {
            chord = chord.parentElement
            chord.classList.add('play-next')
            setModalOpen(false)
        }
    }

    const handleRemove = () => {
        let newState = [...state]
        newState = newState.filter(x => x !== chordId)
        setState(newState)
    }

    return (
    <div className="chord">
        <div id={ chordId }>
            <span className="chord-span" onClick={ event => handleOpenChangeChordModal(event) }>
                <h2 className="chord-name grow">
                    { chordData.displayName }
                </h2>
            </span>
        </div>
        <Modal
        open={modalOpen}
        aria-labelledby="change-chord-modal-title"
        >
            <Box sx={modalStyle}>
            <Typography id="change-chord-modal-title" variant="h6" component="h2">
                Change chord
            </Typography>
            <div>
                <FormControl sx={ controlStyle } fullWidth>
                    <InputLabel id="demo-simple-select-label">Root note</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={ rootNote }
                        label="Root note"
                        onChange={handleChangeRootNote}
                    >
                        {
                        keys.map(item => {
                            return <MenuItem key={ item } value={ item }>{ item }</MenuItem>
                        })
                        }
                    </Select>
                </FormControl>
                <FormControl sx={ controlStyle } fullWidth>
                    <InputLabel id="demo-simple-select-label">Min/Maj</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={ minMaj }
                        label="Min/Maj"
                        disabled={ special !== '' }
                        onChange={handleChangeMinMaj}
                    >
                        <MenuItem value={'min'}>min</MenuItem>
                        <MenuItem value={'maj'}>maj</MenuItem>
                    </Select>
                </FormControl>
                <FormControl sx={ controlStyle } fullWidth>
                    <InputLabel id="demo-simple-select-label">Extension</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={ extension }
                        label="Extension"
                        onChange={handleChangeExtension}
                        disabled={ special !== '' }
                    >
                        <MenuItem value={ '' }> none </MenuItem>
                        {
                        extensions.map(item => {
                            return <MenuItem key={ item } value={ item }>{ item }</MenuItem>
                        })
                        }
                    </Select>
                </FormControl>
                <FormControl sx={ controlStyle } fullWidth>
                    <InputLabel id="demo-simple-select-label">Special</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={ special }
                        label="Special"
                        onChange={handleChangeSpecial}
                    >
                        <MenuItem value={ '' }> none </MenuItem>
                        {
                        specials.map(item => {
                            return <MenuItem key={ item } value={ item }>{ item }</MenuItem>
                        })
                        }
                    </Select>
                </FormControl>
            </div>
            <div>
                <Button onClick={ handlePlayNext }>
                    Play next
                </Button>
                <Button onClick={ handleRemove }>
                    Remove
                </Button>
            </div>
            <Button onClick={ handleApplyChanges }>
                Apply
            </Button>
            <Button onClick={ handleCancelChanges }>
                Cancel
            </Button>
            </Box>
        </Modal>
    </div>
    ) 
}

export { Chord, generateRandomChord }